<template>
    <div class="overflow-hidden">
        <div class="mx-auto max-w-7xl px-8">
            <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div class="lg:pr-8 lg:pt-4">
                    <div class="lg:max-w-lg">
                        <h2 class="text-base font-semibold leading-7 text-secondary">Besser arbeiten</h2>
                        <p class="mt-2 text-3xl font-bold tracking-tight text-neutral-800 sm:text-4xl">
                            Sofort Antworten erhalten </p>
                        <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            <div v-for="feature in features" :key="feature.name" class="relative pl-9">
                                <dt class="inline font-semibold text-neutral-800">
                                    <component :is="feature.icon" class="absolute left-1 top-1 h-5 w-5" aria-hidden="true"/>
                                    {{ feature.name }}
                                </dt>
                                {{ ' ' }}
                                <dd class="inline text-neutral-600">{{ feature.description }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div class="mockup-browser border bg-base-300">
                    <div class="mockup-browser-toolbar">
                        <div class="input">{{ $page.props.app.url }}</div>
                    </div>
                    <video autoplay loop muted playsinline>
                        <source src="/video/docsguide-intro.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ArrowUpTrayIcon, QuestionMarkCircleIcon, UserIcon} from '@heroicons/vue/24/outline';

const features = [
    {
        name: '1. Account anlegen.',
        description:
            'Kein Setup, keine Sales-Pitches. Einfach anmelden und loslegen. Laden Sie Ihre Dokumente hoch und starten Sie sofort mit der Nutzung.',
        icon: UserIcon
    },
    {
        name: '2. Dokumente hochladen.',
        description: 'Erstellen Sie Ihre eigene Bibliothek mit Dokumenten, die für Sie und Ihre Mitarbeiter relevant sind.',
        icon: ArrowUpTrayIcon
    },
    {
        name: '3. Antworten erhalten.',
        description: 'Keine langen Suchen mehr nach Informationen. Einfach Fragen stellen und relevante Antworten erhalten oder passenden Content erzeugen.',
        icon: QuestionMarkCircleIcon
    }
];
</script>
