<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl sm:px-2 lg:px-4">
      <div class="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div class="max-w-3xl">
          <h2 class="text-4xl font-bold tracking-tight text-neutral-800">Künstliche Intelligenz effektiv nutzen</h2>
          <p class="mt-4 text-neutral-600">Mit DocsGuide nutzen Sie KI Anwendungen wie ChatGPT endlich effektiv. Laden
            Sie Ihre Dokumente
            hoch und sofort
            können alle Ihre Mitarbeiter Fragen stellen oder neuen relevanten Content erzeugen.</p>
        </div>

        <div class="mt-8 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">

          <div v-for="incentive in incentives"
               :key="incentive.name"
               class="relative px-10 bg-white shadow-lg sm:rounded-3xl bg-clip-padding bg-opacity-60 border border-gray-200"
               style="backdrop-filter: blur(20px);"
          >
            <div class="max-w-md mx-auto py-10">
              <div class="divide-y divide-gray-200">
                <div class="text-base leading-6 text-neutral-800 sm:text-lg sm:leading-7 space-y-2">
                  <div class="sm:flex-shrink-0">
                    <div v-html="incentive.icon"></div>
                  </div>

                  <div>
                    <h3 class="text-md font-bold text-neutral-800">{{ incentive.name }}</h3>
                    <p class="mt-2 text-sm text-neutral-600">{{ incentive.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const incentives = [
  {
    name: 'Sofort loslegen',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-neutral-600"><path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" /></svg>',
    description: "Laden Sie Ihre Dokumente hoch und starten Sie sofort mit der Nutzung. Kein Setup, keine Meetings - unkompliziert starten."
  },
  {
    name: 'Erschaffen Sie Ihre Bibliothek',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-neutral-600">\n' +
        '  <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z" />\n' +
        '</svg>\n',
    description: "Erstellen Sie Ihre eigene Bibliothek mit Dokumenten, die für Ihre Mitarbeiter relevant sind. So können Sie sicherstellen, dass alle Mitarbeiter immer Zugriff auf die neuesten und aktuellen Informationen haben."
  },
  {
    name: 'Zeit sparen',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-neutral-600">\n' +
        '  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />\n' +
        '</svg>\n',
    description:
        "Durch die Nutzung von DocsGuide können Sie sofort Zeit & Geld sparen. Keine langen Suchen mehr nach Informationen, sondern einfaches Fragen stellen und sofort relevante Antworten erhalten oder relevanten Content erstellen."
  }
];
</script>
