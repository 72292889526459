<script setup>
import {ref} from "vue";
import Blob from "@/Components/Blob.vue";

const selectedFaq = ref(null);

const faqs = [
    {
        title: 'Brauche ich IT-Kenntnisse?',
        content: 'Nein, es werden keine IT-Kenntnisse benötigt. Die Dokumenten können über eine einfache Oberfläche hochgeladen und verwaltet werden. Falls Sie eine direkte & automatisierte Anbindung an Ihre Software wünschen, kann dies über unsere API erfolgen. Sprechen Sie uns dazu gerne an.'
    },
    {
        title: 'Was sind Teams?',
        content: 'Teams sind Gruppen von Mitarbeitern, die gemeinsam auf Dokumente zugreifen und Fragen stellen können. Sie können Teams erstellen und Mitarbeiter hinzufügen, um den Zugriff auf Dokumente zu steuern. Jeder Mitarbeiter kann in mehreren Teams sein.'
    },
    {
        title: 'Gibt es eine API?',
        content: 'Ja, DocsGuide bietet eine Rest-API an, um die Anbindung an Ihre Software zu erleichtern. Über unsere API können Sie sowohl Dokumente verwalten, als auch Fragen stellen und Antworten erhalten. Sprechen Sie uns dazu gerne an.'
    },
    {
        title: 'Ich habe bedenken, kann ich DocsGuide testen?',
        content: 'Natürlich, Sie können DocsGuide 14 Tage kostenlos testen und jederzeit ohne Angabe von Gründen kündigen. Wir würden uns allerdings über Feedback freuen, damit wir DocsGuide noch besser machen können.'
    },
    {
        title: 'Ist DocsGuide das gleiche wie ChatGPT?',
        content: 'Nein, DocsGuide baut auf der Verwendung von verschiedenen KI-Modellen auf. Da sich im Bereich der KI ständig etwas ändert macht es für uns und unsere Kunden keinen Sinn sich mit einem Anbieter zu verheiraten. Aktuell unterstützen wir OpenAIs ChatGPT, aber auch andere (und eigene) KI-Modelle sind denkbar. Sprechen Sie uns dazu gerne an.'
    },
    {
        title: 'Warum benötige ich einen ChatGPT API Key?',
        content: 'Bei DocsGuide können Sie pro Team einen ChatGPT API Key hinterlegen. Das machen wir, damit Sie die volle Kontrolle über die Kosten haben und keinen unnötigen Aufpreis für die API zahlen. Sollten Sie dann mal eine andere KI nutzen wollen, können Sie so einfach steuern, was genutzt wird. Sprechen Sie uns dazu gerne an, wir unterstützen Sie gerne bei der Einrichtung.'
    },
    {
        title: 'Ich benötige Funktionen, die nicht vorhanden sind.',
        content: 'DocsGuide wird ständig weiterentwickelt und der Funktionsumfang erweitert. Sprechen Sie uns dazu gerne an. Wir freuen uns über Ihr Feedback.'
    },
    {
        title: 'Ich benötige Hilfe bei der Einrichtung.',
        content: 'Kein Problem, wir helfen Ihnen gerne bei der Einrichtung. Sprechen Sie uns dazu gerne an.'
    },
    {
        title: 'Ich habe eine Frage, die hier nicht beantwortet wird.',
        content: 'Nehmen Sie einfach Kontakt zu uns auf. Wir helfen Ihnen gerne weiter.'
    }
];

const toggleFaq = (index) => {
    selectedFaq.value = selectedFaq.value === index ? null : index;
};
</script>

<template>
    <div class="py-8 px-8" id="faq">
        <div class="mx-auto max-w-7xl relative">
            <div class="mx-auto max-w-3xl px-4 justify-between flex md:flex-col items-end md:items-center">
                <p class="text-4xl font-bold tracking-tight sm:text-6xl text-neutral-800">FAQs</p>
                <p class="md:mt-6 text-lg text-right md:text-center max-w-40 md:max-w-full text-neutral-600">Fragen? Wir sind für Sie da.</p>
            </div>

            <div class="hidden md:flex absolute top-0 -mt-24 justify-center items-center w-full -z-10">
                <Blob/>
            </div>
        </div>

        <div class="mx-auto max-w-3xl mt-8 bg-base-100">
            <div class="join join-vertical w-full">
                <div v-for="(faq, index) in faqs" :key="index" class="collapse collapse-arrow join-item border border-base-300">
                    <input type="radio" :id="'faq-' + index" name="my-accordion" :checked="selectedFaq === index"/>
                    <div class="collapse-title text-xl font-medium text-neutral-800" @click="toggleFaq(index)">
                        {{ faq.title }}
                    </div>
                    <div class="collapse-content text-neutral-600" v-show="selectedFaq === index">
                        <p v-html="faq.content"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
