<template>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div>
                <h2 class="text-base font-semibold leading-7 text-primary">Alles was Sie brauchen</h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-neutral-800 sm:text-4xl">All-in-one platform</p>
                <p class="mt-6 text-base leading-7 text-neutral-600">
                    Wissen Sie, was Ihre Mitarbeiter nicht wissen? Wo Informationen fehlen? <br/> <span class="text-secondary font-bold">Wir sagen es Ihnen.</span>
                </p>
            </div>
            <dl class="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-neutral-600 sm:grid-cols-2 lg:gap-y-16">
                <div v-for="feature in features" :key="feature.name" class="relative pl-9">
                    <dt class="font-semibold text-neutral-800">
                        <CheckIcon class="absolute left-0 top-1 h-5 w-5 text-neutral-800" aria-hidden="true"/>
                        {{ feature.name }}
                    </dt>
                    <dd class="mt-2 text-neutral-600">{{ feature.description }}</dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script setup>
import {CheckIcon} from '@heroicons/vue/20/solid'

const features = [
    {
        name: 'Teams',
        description: 'Organisieren Sie Ihre Mitarbeiter in Teams und geben Sie ihnen Zugriff auf die Informationen, die sie benötigen.'
    },
    {
        name: 'Statistiken',
        description: 'Wertvolle Insights darüber was gesucht wird. Identifizieren Sie sofort problematische Themen oder Bereiche.'
    },
    {
        name: 'Gewohntes Interface',
        description: 'Sie arbeiten schon mit ChatGPT? Wunderbar, dann kennen Sie und Ihre Mitarbeiter sich bereits aus.'
    },
    {
        name: 'Alle Informationen an einem Ort',
        description: 'Ihre Website, Ihre Dokumente und Handbücher, alles an einem Ort. Finden Sie in Sekunden, was Sie suchen.'
    }
]
</script>
