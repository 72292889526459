<script setup>

</script>

<template>
    <div class="relative isolate overflow-hidden px-6 lg:overflow-visible lg:px-0">
        <div
            class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div
                class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div class="lg:pr-4">
                    <div class="lg:max-w-lg text-neutral-800">
                        <p class="text-base font-semibold leading-7 text-neutral-600">KI aber mit Ihren Dokumenten</p>
                        <h2 class="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">Mehr (Business-)Intelligenz</h2>
                        <p class="mt-6 text-xl leading-8 text-neutral-600">
                            Informationen sind <span class="text-secondary font-bold">wichtig</span>.<br />Sie auch zu
                            finden, ist <span class="text-primary font-bold">entscheidend</span>. </p>
                        <p class="mt-8 text-neutral-600">
                            Fast jedes Unternehmen hat Dokumente, die für die tägliche Arbeit wichtig sind. Oft sind
                            diese Dokumente jedoch unstrukturiert und schwer zu durchsuchen auf irgendeinem Netzlaufwerk
                            abgelegt. Auf der Suche nach Informationen verbringen Mitarbeiter oft Stunden damit
                            Dokumente und Ordner zu durchsuchen. </p>
                        <p class="mt-4 text-neutral-600">
                            Das geht auch einfacher. Wir machen Ihre Dokumente durchsuchbar und finden in Sekunden die
                            passenden Antworten auf die Fragen Ihrer Mitarbeiter und Kunden. </p>
                    </div>
                </div>
            </div>
            <div
                class="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                <img class="" src="/images/content.png" alt="">
            </div>
        </div>
    </div>

</template>

<style scoped></style>
