<template>
    <section class="container mx-auto max-w-6xl px-6">
        <div class="grid grid-flow-row sm:grid-flow-col">
            <div class="md:me-12 p-4 sm:px-10 sm:py-6 md:p-16 md:border-r quote-divider">
                <img src="/images/quote2.svg" class="h-20 w-20 sm:h-20 sm:w-20 mx-auto md:h-auto md:w-auto" alt="quote">
            </div>

            <figure class="flex flex-col justify-center items-center">
                <blockquote>
                    <p class="mb-6 sm:mb-11 md:leading-[34px] text-blue-3 md:text-h4-l text-neutral-600">
                        Indem wir künstliche Intelligenz mit unseren eigenen Daten bereichern, verleihen wir ihr die Fähigkeit, nicht nur zu lernen,
                        sondern uns als Unternehmen zu verstehen. So entstehen Lösungen, die genau auf unsere Bedürfnisse zugeschnitten sind. Aus
                        ungenutzten Daten entsteht so nutzbares Wissen. </p>
                </blockquote>
            </figure>
        </div>
    </section>
</template>

<style scoped>
.quote-divider {
    -o-border-image: linear-gradient(to bottom, #22294d, rgb(41 171 226 / 0%)) 1;
    border-image: linear-gradient(to bottom, #22294d, rgb(41 171 226 / 0%)) 1;
}
</style>
