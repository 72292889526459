<script setup>
import {ref} from "vue";

let anzahlMitarbeiter = ref(20);
let gehalt = ref(50000);
let minuten = ref(60);

const arbeitstageProJahr = 221;

function stundenlohn() {
    return gehalt.value / arbeitstageProJahr / 8
}

function zeitVerschwendungProTag() {
    return (stundenlohn() / 60 * minuten.value) * anzahlMitarbeiter.value
}

function zeitVerschwendungProJahr() {
    return zeitVerschwendungProTag() * arbeitstageProJahr
}

</script>

<template>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl">
            <div>
                <h2 class="text-base font-semibold leading-7 text-secondary">Zeit & Geld</h2>
                <p class="text-3xl font-bold tracking-tight sm:text-4xl text-primary">Suchen kostet.</p>

                <p class="mt-6 text-neutral-600">
                    <input type="number" min="1" v-model="anzahlMitarbeiter" class="input input-primary input-sm max-w-20 mr-1"/> Mitarbeiter
                    verschwenden
                    täglich <input type="number" v-model="minuten" min="10" class="input input-primary input-sm max-w-20 mx-1"/> Minuten mit Suchen?
                </p>
            </div>

            <div class="mt-4 flex flex-col leading-7 text-cloud-50 bg-primary p-6 rounded-xl">
                <div class="flex items-center justify-center font-extrabold text-4xl mb-1.5">
                    <p>Das kostet Sie <span class="text-error">{{ Math.round(zeitVerschwendungProJahr(anzahlMitarbeiter)) + '€' }} </span> pro Jahr
                    </p>
                </div>

                <div class="flex items-center justify-center text-neutral-300">
                    <p class="text-xs">* bei {{ gehalt }}€ Jahresgehalt und {{ arbeitstageProJahr }} Arbeitstagen pro Jahr</p>
                </div>
            </div>
        </div>
    </div>
</template>
